import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import "./Games.css";
import StickyFooter from "./Home/StickyFooter";

const Games = ({ title }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <div className="games-container">
      <h1 className="text-center">Popular Games</h1>
      <div className="hrline"></div>
      <div className="game-product mt-5">
        {products?.map((item, index) => {
          return (
            <div
              key={index}
              className="game"
              onClick={() => navigate(`/product/${item?.name}`)}
            >
              <span className="p-price">Rs. {item?.cost[0]?.price}</span>
              <img src={`https://thoihenofficial.com/${item?.image}`} alt="" />
              <h4 className="m-0 my-2">{item?.name}</h4>
            </div>
          );
        })}
      </div>
      {/* <StickyFooter /> */}
    </div>
  );
};

export default Games;
