import React from "react";
import Layout from "../components/Layout/Layout";
import "./PhoneSkins.css";
import IMAGES from "../img/image";
import HowItWorks from "../components/Home/HowItWorks";
import Products from "../components/Products";
import Games from "../components/Games";
import HeroSlider from "../components/Home/HeroSlider";
import StickyFooter from "../components/Home/StickyFooter";

const PhoneSkins = () => {
  return (
    <Layout>
      <HeroSlider />
      <div className="phone-skin-product-container">
        <Games title={"Popular Games"} />
      </div>
      <HowItWorks />
      <StickyFooter />
    </Layout>
  );
};

export default PhoneSkins;
