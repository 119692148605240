import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import HowItWorks from "../components/Home/HowItWorks";
import StickyFooter from "../components/Home/StickyFooter.js";
import SliderText from "../components/Home/SliderText";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Games from "../components/Games";
import { message } from "antd";
import Testimonials from "../components/Home/Testimonial";
import HeroSlider from "../components/Home/HeroSlider";

const Home = () => {
  const params = useParams();
  const navigate = useNavigate();

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
        } else {
          // localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
    if (params.token) {
      localStorage.setItem("token", params.token);
      navigate("/user-dashboard");
    }
  }, []);

  return (
    <Layout>
      <HeroSlider />
      <Games title={"Popular Games Top-Up"} />
      <StickyFooter />
      <HowItWorks />
    </Layout>
  );
};

export default Home;
