import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const AdminOrder = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  function handleFilter(e) {
    const { value } = e.target;
    if (value === "api") {
      const filterOrders = data?.filter((item) => item.api !== "no");
      setOrders(filterOrders);
    } else if (value === "noapi") {
      const filterOrders = data?.filter((item) => item.api === "no");
      setOrders(filterOrders);
    } else if (value === "all") {
      setOrders(data);
    }
  }

  const getAllOrders = async (e) => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/admin-get-all-orders", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setOrders(res.data.data);
        setData(res.data.data.reverse());
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">All Orders</h3>
        </div>
        <hr />
        <div className="table-container">
          <div className="form-fields mb-3">
            <select
              onChange={handleFilter}
              className="text-dark py-2"
              name="api"
              id=""
            >
              <option value="all">All</option>
              <option value="api">API Orders</option>
              <option value="noapi">Non API Orders</option>
            </select>
          </div>
          <table className="table order-table">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Order Type</th>
                <th>Email</th>
                <th>Total</th>
                <th>Status</th>
                <th>Date</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <small>{item?.orderId}</small>
                    </td>
                    <td>
                      <small>
                        {item?.api === "no" ? "Manual Orders" : "Api Orders"}
                      </small>
                    </td>
                    <td>
                      <small>{item?.customer_email}</small>
                    </td>
                    <td>
                      <small>Rs. {item?.price}</small>
                    </td>
                    <td>
                      <small>{item?.status}</small>
                    </td>
                    <td>
                      <small>
                        {new Date(item?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                      </small>
                    </td>
                    <td>
                      <RemoveRedEyeIcon
                        onClick={() =>
                          navigate(`/admin-view-order/${item?.orderId}`)
                        }
                        className="text-success icon"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminOrder;
