import React from "react";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import IMAGES from "../../img/image";
import "./HeroSlider.css";

const HeroSlider = () => {
  const navigate = useNavigate();
  const location = useLocation();

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 100,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className={`hero-slider ${location.pathname === "/games" && "active"}`}
    >
      <Slider {...settings}>
        <div className="hero-img" onClick={() => navigate("/games")}>
          <img src={IMAGES.banner} alt="" />
        </div>
        <div className="hero-img" onClick={() => navigate("/games")}>
          <img src={IMAGES.banner1} alt="" />
        </div>
        <div className="hero-img" onClick={() => navigate("/games")}>
          <img src={IMAGES.banner2} alt="" />
        </div>
        <div className="hero-img" onClick={() => navigate("/games")}>
          <img src={IMAGES.banner3} alt="" />
        </div>
      </Slider>
    </div>
  );
};

export default HeroSlider;
